import React, {FC} from 'react';
import ReactDOM from 'react-dom';
import {AppContainer} from 'react-hot-loader';

// Your top level component
import App from './App';

// Export your top level component as JSX (for static rendering)
export default App;

// Render your app
if (typeof document !== 'undefined') {
    const target = document.getElementById('root');

    const renderMethod = target.hasChildNodes() ? ReactDOM.hydrate : ReactDOM.render;

    const render = (Comp: FC) => {
        renderMethod(
            <AppContainer>
                <Comp />
            </AppContainer>,
            target
        );
    };

    // Render!
    render(App);

    // Hot Module Replacement
    // @ts-ignore
    if (module && module.hot) {
        // @ts-ignore
        module.hot.accept('./App', () => {
            render(App);
        });
    }
}
