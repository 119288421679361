

import React from 'react'
import universal, { setHasBabelPlugin } from 'react-universal-component'

setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
  ignoreBabelRename: true
}

const t_0 = universal(import('__react_static_root__/src/components/pages/404'), universalOptions)
      t_0.template = '__react_static_root__/src/components/pages/404'
      
const t_1 = universal(import('__react_static_root__/src/components/pages/Home'), universalOptions)
      t_1.template = '__react_static_root__/src/components/pages/Home'
      
const t_2 = universal(import('__react_static_root__/src/components/pages/Shop'), universalOptions)
      t_2.template = '__react_static_root__/src/components/pages/Shop'
      
const t_3 = universal(import('__react_static_root__/src/components/pages/IncredibleTemplate'), universalOptions)
      t_3.template = '__react_static_root__/src/components/pages/IncredibleTemplate'
      
const t_4 = universal(import('__react_static_root__/src/components/pages/ImageGallery'), universalOptions)
      t_4.template = '__react_static_root__/src/components/pages/ImageGallery'
      
const t_5 = universal(import('__react_static_root__/src/components/pages/Videos'), universalOptions)
      t_5.template = '__react_static_root__/src/components/pages/Videos'
      
const t_6 = universal(import('__react_static_root__/src/components/pages/Athletes'), universalOptions)
      t_6.template = '__react_static_root__/src/components/pages/Athletes'
      
const t_7 = universal(import('__react_static_root__/src/components/pages/StrongManNews'), universalOptions)
      t_7.template = '__react_static_root__/src/components/pages/StrongManNews'
      
const t_8 = universal(import('__react_static_root__/src/components/pages/Sponsors'), universalOptions)
      t_8.template = '__react_static_root__/src/components/pages/Sponsors'
      
const t_9 = universal(import('__react_static_root__/src/components/pages/Checkout'), universalOptions)
      t_9.template = '__react_static_root__/src/components/pages/Checkout'
      

// Template Map
export default {
  '__react_static_root__/src/components/pages/404': t_0,
'__react_static_root__/src/components/pages/Home': t_1,
'__react_static_root__/src/components/pages/Shop': t_2,
'__react_static_root__/src/components/pages/IncredibleTemplate': t_3,
'__react_static_root__/src/components/pages/ImageGallery': t_4,
'__react_static_root__/src/components/pages/Videos': t_5,
'__react_static_root__/src/components/pages/Athletes': t_6,
'__react_static_root__/src/components/pages/StrongManNews': t_7,
'__react_static_root__/src/components/pages/Sponsors': t_8,
'__react_static_root__/src/components/pages/Checkout': t_9
}
// Not Found Template
export const notFoundTemplate = "__react_static_root__/src/components/pages/404"

